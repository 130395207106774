import { useLocation } from "@reach/router"
import axios from "axios"
import DOMPurify from "dompurify"
import { Script } from "gatsby"
import { parse as queryparse } from "query-string"
import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import PhoneInput from "react-phone-input-2"
import Select from "react-select"
import {
  getAdditionalInfo,
  sendEventHumioLog,
  setHubspotutkCookie,
} from "../../common/FormBuilder/Form-Function"
import SelectOld from "../../utility/select"
import {
  checkCommonDomain,
  checkInvalid,
  checkValidEmail,
  checkValidName,
  checkValidUrl,
} from "../../utility/validation"
import SVGIcon from "../SVGIcon"
import Buttons from "../button"
import "./Form-builder.scss"

const FormBuilderWaf = ({
  multiFormData,
  setMultiFormData,
  page,
  setPage,
  emptyFields,
  setEmptyFields,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [paramXeroProd, setparamXeroProd] = useState()
  let captchaStatus = true
  const [defaultCountryCode, setDefaultCountryCode] = useState("us")
  const locationnew = useLocation()
  const searchParams = queryparse(locationnew.search)
  useEffect(() => {
    if (
      searchParams?.src === "xero" ||
      searchParams?.src === "productOnboarding"
    )
      setparamXeroProd(true)
  }, [searchParams])

  const [validity, setValidity] = useState(
    props.noCaptchaValidation ? true : false
  )
  const [uploadedType, setUploadedType] = useState() //getting file type uploaded and setting in state to verify and show error

  const [growsumo, setGrowsumo] = useState()
  useEffect(() => {
    setGrowsumo(window.growsumo)
  }, [])

  const [data, setData] = useState(
    props.type === "subscribe"
      ? [
          {
            name: "email",
            type: "email",
            placeholder: "Email address",
            errorMessage: "Please enter a valid email",
          },
        ]
      : props?.data
  )

  useEffect(() => {
    multiFormData &&
      setData(
        props.type === "subscribe"
          ? [
              {
                name: "email",
                type: "email",
                placeholder: "Email address",
                errorMessage: "Please enter a valid email",
              },
            ]
          : props?.data
      )
  }, [props.data])

  const currentLocation = useLocation()

  function CheckIpdata(resdata, alt) {
    const data = resdata != null && resdata !== "" ? resdata : alt
    return data
  }

  const [IpData, setIpData] = useState({
    continent: "No Continent",
    continentcode: "No Continentcode",
    countrycode: "No Countrycode",
    country: "No Country",
    state: "No State",
    city: "No City",
    zip: "No Zip",
    countryphoneCode: 0,
  })

  const [dailCodeLength, setDailCodeLength] = useState(0)

  useEffect(() => {
    if (
      props.IpStackData ||
      data.filter(el => el.type === "phone").length > 0
    ) {
      axios.get(process.env.IP_STACK_API).then(function(response) {
        if (response?.data?.country_code) {
          props.currentLocation &&
            props.currentLocation(
              response?.data?.country_code ? response?.data?.country_code : "US"
            )
          setIpData({
            continent: CheckIpdata(
              response?.data?.continent_name,
              IpData.continent
            ),
            continentcode: CheckIpdata(
              response?.data?.continent_code,
              IpData.continentcode
            ),
            countrycode: CheckIpdata(
              response.data?.country_code,
              IpData.countrycode
            ),
            country: CheckIpdata(response?.data?.country_name, IpData.country),
            state: CheckIpdata(response?.data?.region_name, IpData.state),
            city: CheckIpdata(response?.data?.city, IpData.city),
            zip: CheckIpdata(response?.data?.zip, IpData.zip),
            countryphoneCode: CheckIpdata(
              response?.data?.location?.calling_code,
              IpData.countryphoneCode
            ),
          })
          setDefaultCountryCode(response?.data?.country_code?.toLowerCase())
        } else {
          axios.get(process.env.IP_REGISTRY_API).then(response => {
            props.currentLocation &&
              props.currentLocation(
                response?.data?.location?.country?.code
                  ? response?.data?.location?.country?.code
                  : "US"
              )
            setIpData({
              continent: CheckIpdata(
                response?.data?.location?.continent?.name,
                IpData.continent
              ),
              continentcode: CheckIpdata(
                response?.data?.location?.continent?.code,
                IpData.continentcode
              ),
              countrycode: CheckIpdata(
                response?.data?.location?.country?.code,
                IpData.countrycode
              ),
              country: CheckIpdata(
                response?.data?.location?.country?.name,
                IpData.country
              ),
              state: CheckIpdata(
                response?.data?.location?.region?.name,
                IpData.state
              ),
              city: CheckIpdata(response?.data?.location?.city, IpData.city),
              zip: CheckIpdata(response?.data?.location?.postal, IpData.zip),
              countryphoneCode: CheckIpdata(
                response?.data?.location?.country?.calling_code,
                IpData.countryphoneCode
              ),
            })
            setDefaultCountryCode(
              response?.data?.location?.country?.code?.toLowerCase()
            )
          })
        }
      })
    }
  }, [])

  // useEffect(() => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id)

  //     if (!isScriptExist) {
  //       var script = document.createElement("script")
  //       script.type = "text/javascript"
  //       script.src = url
  //       script.id = id
  //       script.onload = function() {
  //         if (callback) callback()
  //       }
  //       document.body.appendChild(script)
  //     }

  //     if (isScriptExist && callback) callback()
  //   }

  //   loadScriptByURL(
  //     "recaptcha-key",
  //     `https://www.google.com/recaptcha/api.js?render=${process.env.CAPTCHA_KEY}`,
  //     function() {
  //       // console.log("Script loaded!")
  //     }
  //   )
  // }, [])

  const IpStackData = [
    {
      name: "continent",
      value: IpData.continent,
    },
    {
      name: "continent_code",
      value: IpData.continentcode,
    },
    {
      name: "country_code",
      value: IpData.countrycode,
    },
    {
      name: "country",
      value: IpData.country,
    },
    {
      name: "state",
      value: IpData.state,
    },
    {
      name: "city",
      value: IpData.city,
    },
    {
      name: "zip",
      value: IpData.zip,
    },
    {
      name: "country_phone_code",
      value: IpData.countryphoneCode,
    },
  ]

  function handleChange(val, idx, itemType) {
    const updatedData = [...data]
    if (itemType === "phone") {
      setDailCodeLength(val.data.dialCode.length)
      updatedData[idx].value = val.value
      // updatedData[idx].error = //false can be used to change error to false on change
    } else if (itemType === "select") {
      let selectedOptions = val.map(v => v.value)
      updatedData[idx].value = selectedOptions.join(", ")
    } else if (itemType === "singleSelect") {
      updatedData[idx].value = val
    } else {
      updatedData[idx].value = val
      // updatedData[idx].error = //false can be used to change error to false on change
    }
    setData(updatedData)
  }

  function enableError(idx) {
    const updatedData = [...data]
    updatedData[idx].error = true
    setData(updatedData)
  }

  function resetError(idx) {
    const updatedData = [...data]
    updatedData[idx].error = false
    setData(updatedData)
  }

  function checkInput() {
    data.map((el, idx) => {
      if (el.type === "email") {
        if (
          el?.value?.length === 0 ||
          (props.disallowCommonDomains && checkCommonDomain(el.value)) ||
          !checkValidEmail(el.value) ||
          el.value === undefined
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.name === "firstname") {
        if (
          !checkValidName(el.value) ||
          el?.value?.length === 0 ||
          el.value === undefined
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el?.validateEmpty) {
        if (el?.value?.length === 0 || el.value === undefined) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "singleSelect") {
        if ((el.required ?? true) && !el?.value?.value) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "phone") {
        if (
          (el.required ?? true) &&
          (el?.value?.slice(dailCodeLength).length === 0 ||
            checkInvalid(el?.value?.slice(dailCodeLength)) ||
            el?.value?.slice(dailCodeLength) === undefined)
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "select") {
        //select input type validation
        if (!(el?.value?.length > 0)) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
        return
      } else if (el.type === "checkbox") {
        let enabledChecks = []
        el.choices.map((ele, ind) => {
          let checkbox = document.getElementById(ele)
          if (checkbox.checked) {
            enabledChecks.push(el.data[ind])
          }
        })
        if ((el.required ?? true) && !enabledChecks.length) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "radio") {
        let enabledChecks = []
        el.choices.map((ele, ind) => {
          let checkbox = document.getElementById(ele)
          if (checkbox.checked) {
            enabledChecks.push(el.data[ind])
          }
        })
        if ((el.required ?? true) && !enabledChecks.length) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "file") {
        if (
          (uploadedType?.data?.type != el.acceptFiletype ||
            uploadedType?.data?.size > 10485760) &&
          uploadedType != undefined
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        } else if (el.required && uploadedType === undefined) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "url") {
        if (
          (el.required ?? true) &&
          (checkValidUrl(el.value) || el.value === undefined || el.value == "")
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "textarea") {
        if (
          (el.required ?? true) &&
          (checkInvalid(el.value) || el.value === undefined || el.value == "")
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else {
        if (
          (el.required ?? true) &&
          (el?.value?.length === 0 ||
            checkInvalid(el.value) ||
            el.value === undefined)
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      }
    })
  }

  const isSubmitValid = () => {
    const validationArr = data.filter((el, idx) => {
      if (el.error === true) {
        return el // no error
      }
    })
    return validationArr.length === 0 // returns true if there is no error
  }

  const getCaptchaToken = async () => {
    return new Promise(resolve => {
      window.hcaptcha
        .execute({ async: true })
        .then(({ response: token, key }) => {
          return resolve(token)
        })
    })
  }

  const validateCaptchaApi = async param => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    let res = await axios
      .get(
        `${process.env.CAPTCHA_API}?token=${param}&captchaProvider=HCAPTCHA`,
        config
      )
      .catch(error => {
        sendEventHumioLog(error.response, `Captcha Api Error`)
        props.errorHandler && props.errorHandler(data, error.response)
      })
    return res
  }

  const formSubmit = async (
    items,
    endpoint,
    pageUri,
    pageName,
    errorHandler
  ) => {
    let url = endpoint
    let additionalInfo = getAdditionalInfo(captchaStatus)

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    let data = {
      submittedAt: Date.now(),
      fields: [...items, ...additionalInfo],
      context: {
        hutk: setHubspotutkCookie("hubspotutk"),
        pageUri: pageUri,
        pageName: `${pageName}`,
      },
    }
    let final_data = JSON.stringify(data)
    sendEventHumioLog(final_data, `Before hitting submit from ${document.URL}`)

    if (props.demoPage) {
      if (captchaStatus) {
        const response = await axios
          .post(url, {
            submittedAt: Date.now(),
            fields: [...items, ...additionalInfo],
            config,
            context: {
              hutk: setHubspotutkCookie("hubspotutk"),
              pageUri: pageUri,
              pageName: `${pageName}`,
            },
          })
          .then(response => {
            if (response.request.status === 200) {
              setLoading(false)
              if (growsumo?.data) {
                const email = data.fields.filter(x => x.name === "email")[0]
                  .value
                const name = data.fields.filter(x => x.name === "firstname")[0]
                  .value
                growsumo.data.name = name
                growsumo.data.email = email
                growsumo.data.customer_key = email
                growsumo.createSignup()
              }
              if (props.afterSubmit) {
                props.afterSubmit(response, items)
              }

              sendEventHumioLog(
                final_data,
                response.data.inlineMessage,
                response.request.status,
                response.request.responseUrl,
                `After hitting submit & got success from ${document.URL}`
              )
              if (props.path) {
                window.location.href = `/thank-you?src=${props.path}`
              }

              return response
            } else if (
              response.request.status === 400 ||
              response.request.status === 403 ||
              response.request.status === 404
            ) {
              setLoading(false)
              sendEventHumioLog(
                final_data,
                response.data.inlineMessage,
                response.request.status,
                response.request.responseUrl,
                `After hitting submit & got failed from ${document.URL}`
              )
              const res = JSON.parse(response.request.responseText)
              errorHandler(data, res)
            }
          })
      } else {
        setLoading(false)
      }
    } else {
      const response = await axios
        .post(url, {
          submittedAt: Date.now(),
          fields: [...items, ...additionalInfo],
          config,
          context: {
            hutk: setHubspotutkCookie("hubspotutk"),
            pageUri: pageUri,
            pageName: `${pageName}`,
          },
        })
        .then(response => {
          if (response.request.status === 200) {
            setLoading(false)
            if (props.afterSubmit) {
              props.afterSubmit(response, items)
            }

            sendEventHumioLog(
              final_data,
              response.data.inlineMessage,
              response.request.status,
              response.request.responseUrl,
              `After hitting submit & got success from ${document.URL}`
            )
            if (props.path) {
              window.location.href = `/thank-you?src=${props.path}`
            }

            return response
          } else if (
            response.request.status === 400 ||
            response.request.status === 403 ||
            response.request.status === 404
          ) {
            setLoading(false)
            sendEventHumioLog(
              final_data,
              response.data.inlineMessage,
              response.request.status,
              response.request.responseUrl,
              `After hitting submit & got failed from ${document.URL}`
            )
            const res = JSON.parse(response.request.responseText)
            errorHandler(data, res)
          }
        })
    }
  }

  const getFileUploadUrl = async () => {
    let url = null
    let file = document.getElementById("uploaded-file").files[0]
    let form = new FormData()
    form.append("fileUpload", file)
    if (
      file &&
      uploadedType?.data?.type === uploadedType?.input?.acceptFiletype &&
      uploadedType?.data?.size < 10485760
    ) {
      let response = await fetch(`${process.env.GRAPHCMS_API_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_GRAPHCMS_AUTH_TOKEN_SPOS}`,
        },
        body: form,
      }).catch(error => {
        props.errorHandler && props.errorHandler(data, error.response)
      })

      const resData = await response.json()

      url = resData.url
      return url
    }
    return "No File"
  }

  function wafError(error) {
    document.getElementById("error-container").innerHTML = error.message
  }

  const handleSubmit = async e => {
    setLoading(true)
    e.preventDefault()
    checkInput()

    const FinalSubmit = async () => {
      let finalData = []
      data.map((el, idx) => {
        if (el.type !== "file") {
          if (el.type === "checkbox") {
            let enabledChecks = []
            el.choices.map((ele, ind) => {
              let checkbox = document.getElementById(ele)
              if (checkbox.checked) {
                enabledChecks.push(el.data[ind])
              }
            })
            enabledChecks = enabledChecks.join(";")
            finalData = [
              ...finalData,
              { name: el.name, value: DOMPurify.sanitize(enabledChecks) },
            ]
          } else if (el.type === "radio") {
            let enabledCheck = ""
            el.choices.map((ele, ind) => {
              let checkbox = document.getElementById(ele)
              if (checkbox.checked) {
                enabledCheck = el.data[ind]
              }
            })
            finalData = [
              ...finalData,
              { name: el.name, value: DOMPurify.sanitize(enabledCheck) },
            ]
          } else if (el.type === "singleSelect") {
            finalData = [
              ...finalData,
              { name: el.name, value: DOMPurify.sanitize(el.value.value) },
            ]
          } else {
            finalData = [
              ...finalData,
              { name: el.name, value: DOMPurify.sanitize(el.value) },
            ]
          }
        }
      })

      if (props.roleApplied) {
        finalData = [
          ...finalData,
          { name: "role_applied", value: props.roleApplied },
        ]
      }

      if (props.IpStackData) {
        finalData.push(...IpStackData)
      }

      if (props?.extradata) {
        finalData.push(props?.extradata)
      }

      if (props.type === "subscribe") {
        formSubmit(
          finalData,
          props.endpoint
            ? props.endpoint
            : process.env.HUBSPOT_SUBSCRIBE_API_ENDPOINT,
          props.url ? props.url : process.env.HUBSPOT_SUBSCRIBE_API_URL,
          props.formName ? props.formName : "Subscription - Superops",
          props.path ? props.path : "subscription"
        )
      } else {
        let fullName = finalData.filter(el => el.name === "firstname")[0]
        if (multiFormData) {
          const separateObject = obj => {
            const res = []
            const keys = Object.keys(obj)
            keys.forEach(key => {
              res.push({
                name: key,
                value: obj[key],
              })
            })
            return res
          }
          finalData.push(...separateObject(multiFormData))
          finalData = finalData.filter(
            (v, i, a) => a.findIndex(v2 => v2.name === v.name) === i
          )
        }
        let dataObj = {}
        finalData.map(el => (dataObj[el.name] = el.value))
        if (!dataObj.hasOwnProperty("lastname")) {
          if (fullName.value.includes(" ")) {
            let firstName = fullName.value.substr(
              0,
              fullName.value.indexOf(" ")
            )
            let lastName = fullName.value.substr(
              fullName.value.indexOf(" ") + 1
            )
            fullName.value = firstName
            finalData.push({ name: "lastname", value: lastName })
          } else {
            finalData.push({ name: "lastname", value: " " })
          }
        }

        if (props.sessionStorage) {
          finalData.map((el, idx) => sessionStorage.setItem(el.name, el.value))
        }
        if (props.fileUpload) {
          let fileUrl = await getFileUploadUrl()
          finalData = [
            ...finalData,
            { name: "upload_file_link", value: fileUrl },
          ]
          formSubmit(finalData, props.endpoint, props.url, props.formName)
        } else {
          formSubmit(finalData, props.endpoint, props.url, props.formName)
        }
      }
    }

    if (props.demoPage) {
      if (!props.noCaptchaValidation) {
        const container = document.getElementById("captcha-container")
        window?.AwsWafCaptcha.renderCaptcha(container, {
          apiKey: process.env.AWS_WAF_API_KEY,
          onSuccess: () => {
            console.log("hello")
            document.getElementById("fetch-data-btn").style.display = "block"
          },
          onError: error => {
            console.log("AwsWafCaptcha error")
            wafError(error)
          },
        })

        // if (isSubmitValid()) {
        //   const token = await getCaptchaToken()
        //   const captchaValid = await validateCaptchaApi(token)
        //   captchaStatus = captchaValid?.data?.status
        //   if (props.ErrorTextSetter) {
        //     if (captchaStatus) props.ErrorTextSetter(null)
        //     else props.ErrorTextSetter("Oh ho! Captcha validation failed")
        //   }
        //   FinalSubmit()
        // } else {
        //   setLoading(false)
        // }
      } else {
        if (isSubmitValid()) {
          //   FinalSubmit()
        } else {
          setLoading(false)
        }
      }
    } else {
      if (!props.noCaptchaValidation) {
        let token = await getCaptchaToken()
        let captchaValid = await validateCaptchaApi(token)
        captchaStatus = captchaValid?.data?.status
        if (captchaStatus && isSubmitValid()) {
          setValidity(true)
          FinalSubmit()
        } else {
          setLoading(false)
        }
      } else {
        if (isSubmitValid()) {
          FinalSubmit()
        } else {
          setLoading(false)
        }
      }
    }
  }

  const fetchProtectedData = () => {
    console.log("fetchProtectedData")
    window.AwsWafIntegration.fetch("./pets", {
      method: "GET",
    })
      .then(response => response.json())
      .then(data => {
        document.getElementById("pets-container").textContent = JSON.stringify(
          data
        )
      })
      .catch(wafError)
  }

  return (
    <>
      <Script
        src={`https://3dedf0ad0084.edge.captcha-sdk.awswaf.com/3dedf0ad0084/jsapi.js`}
      />
      <div id="captcha-container" />
      <button
        id="fetch-data-btn"
        style={{ display: "none" }}
        onClick={() => fetchProtectedData()}
      >
        Fetch data
      </button>
      <div id="pets-container"></div>
      <div id="error-container"></div>
      {
        <form
          className={props.formClassName}
          id={props.id}
          onSubmit={evt => handleSubmit(evt)}
        >
          {data.map((item, idx) => {
            if (
              item.type === "text" ||
              item.type === "password" ||
              item.type === "number" ||
              item.type === "email"
            ) {
              return (
                <>
                  {props.referFriend && item.name === "referrer_name" && (
                    <div className="p13 input-header-wrapper">
                      <p className="input-header">Your details</p>
                    </div>
                  )}
                  {props.referFriend && item.name === "firstname" && (
                    <div className="p13 input-header-wrapper">
                      <p className="input-header">Referral's details</p>
                    </div>
                  )}
                  <div
                    className={`position-relative ${
                      item.parentClassName ? item.parentClassName : ""
                    }`}
                  >
                    <input
                      type={item.type}
                      className={`${
                        props.inputClassName ? props.inputClassName : ""
                      } ${item.error ? props.inputErrorClassName : ""} ${
                        props.submissionError &&
                        props.submissionError.type === item.type
                          ? props.inputErrorClassName
                          : ""
                      } ${item.inputClassName ? item.inputClassName : ""} ${
                        item?.value?.length > 0 ? "valid" : ""
                      } ${paramXeroProd && "valid"}`}
                      placeholder={item.placeholder}
                      onChange={e => {
                        handleChange(e.target.value, idx, item.type)
                        multiFormData &&
                          setMultiFormData({
                            ...multiFormData,
                            [item.name]: e.target.value,
                          })
                      }}
                      value={item.value}
                      name={item.name}
                    />
                    <span className="floating-label p14 position-absolute">
                      {" "}
                      <p>{item.label}</p>
                    </span>

                    {(item.error ||
                      (props.submissionError &&
                        props.submissionError.type === item.type)) && (
                      <div className={props.errorClassName}>
                        {" "}
                        <p>{item.errorMessage}</p>{" "}
                      </div>
                    )}
                  </div>
                </>
              )
            }

            if (item.type === "radio") {
              return (
                <div className="form-builder">
                  <div className="d-flex flex-wrap align-items-center gap-8 mt16 mb10">
                    <div className="position-relative">
                      {item.title && (
                        <p className="p14 font-roboto form-text mb16 fw-500">
                          {item.title}
                        </p>
                      )}

                      {item.title &&
                        (item.error ||
                          (props.submissionError &&
                            props.submissionError.type === item.type)) && (
                          <div
                            className={`${props.errorClassName} checkbox-error`}
                          >
                            <p>{item.errorMessage}</p>{" "}
                          </div>
                        )}
                    </div>
                  </div>

                  <Col
                    lg={12}
                    className="d-flex flex-wrap gap-24 checkbox-wrapper"
                  >
                    {item.choices.map((ele, ind) => {
                      return (
                        <div className="position-relative d-inline-flex">
                          <input
                            type="radio"
                            id={ele}
                            name={"ele"}
                            value={ele}
                            className=" m-0"
                          />
                          <label
                            for={ele}
                            className="checkbox-label d-flex align-items-center"
                          >
                            <span className="checkbox-name">
                              <p className="one m-0 fw-600">{ele}</p>
                            </span>
                          </label>
                        </div>
                      )
                    })}
                  </Col>
                </div>
              )
            }

            if (item.type === "checkbox") {
              return (
                <div className="form-builder">
                  {item.title && (
                    <div className="d-flex flex-wrap align-items-center gap-8 mt16 mb10">
                      <div className="position-relative">
                        <p className="p14 font-roboto form-text mb10 fw-500">
                          {item.title}
                        </p>

                        {item.title &&
                          (item.error ||
                            (props.submissionError &&
                              props.submissionError.type === item.type)) && (
                            <div
                              className={`${props.errorClassName} checkbox-error`}
                            >
                              <p>{item.errorMessage}</p>{" "}
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                  <Col
                    lg={12}
                    className="d-flex flex-wrap gap-24 checkbox-wrapper"
                  >
                    {item.choices.map((ele, ind) => {
                      return (
                        <div className="position-relative d-inline-flex">
                          <input
                            type="checkbox"
                            id={ele}
                            name={ele}
                            value={ele}
                            className="checkbox m-0"
                          />
                          <label
                            for={ele}
                            className="checkbox-label d-flex align-items-center"
                          >
                            <span className="checkbox-name">
                              <p className="one m-0 fw-600">{ele}</p>
                            </span>
                          </label>
                        </div>
                      )
                    })}
                  </Col>
                </div>
              )
            }

            if (item.type === "url") {
              return (
                <>
                  <div
                    className={`position-relative ${
                      item.parentClassName ? item.parentClassName : ""
                    }`}
                  >
                    <input
                      type={item.type}
                      className={`${
                        props.inputClassName ? props.inputClassName : ""
                      } ${item.error ? props.inputErrorClassName : ""} ${
                        props.submissionError &&
                        props.submissionError.type === item.type
                          ? props.inputErrorClassName
                          : ""
                      } ${item.inputClassName ? item.inputClassName : ""} ${
                        item?.value?.length > 0 ? "valid" : ""
                      }`}
                      placeholder={item.placeholder}
                      onChange={e => {
                        handleChange(e.target.value, idx, item.type)
                        multiFormData &&
                          setMultiFormData({
                            ...multiFormData,
                            [item.name]: e.target.value,
                          })
                      }}
                      value={item.value}
                      name={item.name}
                    />
                    <span className="floating-label p14 position-absolute">
                      {" "}
                      <p>{item.label}</p>
                    </span>

                    {(item.error ||
                      (props.submissionError &&
                        props.submissionError.type === item.type)) && (
                      <div className={props.errorClassName}>
                        {" "}
                        <p>{item.errorMessage}</p>{" "}
                      </div>
                    )}
                  </div>
                </>
              )
            }

            if (item.type === "textarea") {
              return (
                <>
                  <div
                    className={`position-relative ${
                      item.parentClassName ? item.parentClassName : ""
                    } ${item.parentClassName ? item.parentClassName : ""}`}
                  >
                    <textarea
                      col={item.col}
                      className={`${
                        props.inputClassName ? props.inputClassName : ""
                      } ${item.error ? props.inputErrorClassName : ""} ${
                        item?.inputClassName ? item.inputClassName : ""
                      } ${item?.value?.length > 0 ? "valid" : ""}`}
                      placeholder={item.placeholder}
                      onChange={e => {
                        handleChange(e.target.value, idx, item.type)
                        multiFormData &&
                          setMultiFormData({
                            ...multiFormData,
                            [item.name]: e.target.value,
                          })
                      }}
                      value={item.value}
                      name={item.name}
                      maxLength={item?.maxLength ? item?.maxLength : ""}
                    />
                    <span className="floating-label p14 position-absolute">
                      <p>{item.label}</p>
                    </span>
                    {item.error && (
                      <div className={props.errorClassName}>
                        {" "}
                        <p>{item.errorMessage}</p>{" "}
                      </div>
                    )}
                  </div>
                </>
              )
            }

            if (item.type === "phone") {
              return (
                <>
                  <div
                    className={`position-relative phone-number ${
                      item.error ? "phone-error" : ""
                    }`}
                  >
                    <PhoneInput
                      id="phone"
                      defaultErrorMessage={item.errorMessage}
                      name={item.name}
                      country={defaultCountryCode}
                      value={item.value}
                      onChange={(value, data) =>
                        handleChange({ value, data }, idx, item.type)
                      }
                      inputClass={item.error ? props.inputErrorClassName : ""}
                      enableLongNumbers
                    />
                    {item.error && (
                      <div className={props.errorClassName}>
                        {" "}
                        <p>{item.errorMessage}</p>{" "}
                      </div>
                    )}
                  </div>
                </>
              )
            }

            if (item.type === "select") {
              return (
                <div
                  className={`position-relative select-wrap ${
                    item.parentClassName ? item.parentClassName : ""
                  }`}
                >
                  <SelectOld
                    id="select"
                    choices={item.choices}
                    name={item.name}
                    label={item.label}
                    onChange={val => handleChange(val, idx, item.type)}
                  />
                  {item.error && (
                    <div className={props.errorClassName}>
                      {" "}
                      <p>{item.errorMessage}</p>{" "}
                    </div>
                  )}
                </div>
              )
            }

            if (item.type === "singleSelect") {
              return (
                <div
                  className={`position-relative select-wrap ${
                    item.parentClassName ? item.parentClassName : ""
                  }`}
                >
                  <Select
                    value={item.value}
                    onChange={val => handleChange(val, idx, item.type)}
                    placeholder={item.label}
                    options={item.choices}
                    className="select-wrap"
                    classNamePrefix="select_inner"
                  />
                  {item.error && (
                    <div className={props.errorClassName}>
                      {" "}
                      <p>{item.errorMessage}</p>{" "}
                    </div>
                  )}
                </div>
              )
            }

            if (item.type === "file") {
              function readFile(input) {
                if (input.files && input.files[0]) {
                  const label = document.getElementById("file-label")
                  label.setAttribute("data-title", input.files[0].name)
                  const icon = document.getElementById("fileupload-icon")
                  icon?.remove()
                  setUploadedType({
                    data: input.files[0],
                    input: item,
                  })
                }
              }
              return (
                <div
                  className={`position-relative ${item.parentClassName &&
                    item.parentClassName}`}
                  style={{ marginTop: "-8px" }}
                >
                  <label
                    className="input-drag-n-drp w-100"
                    id="file-label"
                    data-title="Choose or drag and drop a file"
                  >
                    <input
                      id="uploaded-file"
                      type="file"
                      accept={item.acceptFiletype}
                      onChange={e => {
                        readFile(e.target)
                      }}
                    />
                    <SVGIcon
                      name="attach-file-icon"
                      className="position-absolute drag-icon"
                      id="fileupload-icon"
                    />
                  </label>
                  <div className="text-center mb24">
                    <span className="form-note">
                      *{" "}
                      <span className="p14 prelative">
                        Maximum upload file size {"<"} 10 MB
                      </span>
                    </span>
                  </div>
                  {item.error && (
                    <div className={props.errorClassName}>
                      {" "}
                      <p>{item.errorMessage}</p>{" "}
                    </div>
                  )}
                </div>
              )
            }
            if (item.type === "hidden") {
              return (
                <input
                  type={item.type}
                  className={`dspnone`}
                  value={item.value}
                />
              )
            }
          })}

          {props.hiddenCountry && (
            <div className={`position-relative d-none`}>
              <input
                type="hidden"
                className={` ${
                  props.inputClassName ? props.inputClassName : ""
                } `}
                value={IpData.country}
                name="country"
              />
              <span className="floating-label p14 position-absolute">
                <p>Country</p>
              </span>
            </div>
          )}
          {multiFormData ? (
            page === 0 ? (
              <div className="d-flex justify-content-end">
                <div className="btn-wrapper">
                  <Buttons
                    // onClick={evt => handleSubmit(evt)}
                    theme={props.buttonClassName}
                    disableButton={emptyFields}
                    onClick={() => setPage(page + 1)}
                    text={props.buttonText}
                    id={props.buttonId}
                  />
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-end"
                style={{ gap: "8px" }}
              >
                <div className="btn-wrapper">
                  <Buttons
                    // onClick={evt => handleSubmit(evt)}
                    theme={`${props.buttonClassName}`}
                    disableButton={loading}
                    onClick={() => setPage(page - 1)}
                    text={"Previous"}
                    id={props.buttonId}
                  />
                </div>
                <div className="btn-wrapper">
                  <Buttons
                    onClick={evt => handleSubmit(evt)}
                    theme={props.buttonClassName}
                    disableButton={loading}
                    text={
                      loading ? (
                        <div className="loading-anim">
                          LOADING
                          <span className="ellipsis-anim">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </span>
                        </div>
                      ) : (
                        props.buttonText
                      )
                    }
                    id={props.buttonId}
                  />
                </div>
              </div>
            )
          ) : (
            <Buttons //NOTE: This btn
              // onClick={evt => handleSubmit(evt)}
              theme={props.buttonClassName}
              disableButton={loading}
              text={
                loading ? (
                  <div className="loading-anim">
                    LOADING
                    <span className="ellipsis-anim">
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </span>
                  </div>
                ) : (
                  props.buttonText
                )
              }
              id={props.buttonId}
            />
          )}
          {props.children}
        </form>
      }
    </>
  )
}

export default FormBuilderWaf

//https://api-msp.superops.ai/accounts-web/accounts/validateCaptcha
